import { Pill } from "@clipboard-health/ui-components";

import {
  PlacementApplicationStatus,
  placementApplicationStatusMap,
} from "../types/fetchPlacements.schema";

interface PlacementApplicationStatusChipProps {
  status: PlacementApplicationStatus;
}

export function PlacementApplicationStatusChip(props: PlacementApplicationStatusChipProps) {
  const { status } = props;

  let chipBackgroundColor: "info" | "success" | "warning";
  let chipLabel: string;

  switch (status) {
    case PlacementApplicationStatus.INTERVIEW: {
      chipBackgroundColor = "info";
      chipLabel = "Interview held";
      break;
    }

    case PlacementApplicationStatus.OFFER: {
      chipBackgroundColor = "success";
      chipLabel = "Offer made";
      break;
    }

    case PlacementApplicationStatus.HIRED: {
      chipBackgroundColor = "success";
      chipLabel = "Offer accepted";
      break;
    }

    default: {
      chipBackgroundColor = "info";
      chipLabel = placementApplicationStatusMap[status].label;
      break;
    }
  }

  return (
    <Pill
      variant="filled"
      size="small"
      label={chipLabel}
      color={(theme) => theme.palette[chipBackgroundColor].contrastText}
      iconColor={(theme) => theme.palette[chipBackgroundColor].contrastText}
      backgroundColor={(theme) => theme.palette[chipBackgroundColor].main}
      sx={{ width: "fit-content", alignSelf: "flex-start" }}
    />
  );
}
