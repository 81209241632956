import { CbhIcon, DotSeparatedList } from "@clipboard-health/ui-components";
import { BaseLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
// TODO: Use our own Card, Pill instead of Chip
// eslint-disable-next-line no-restricted-imports
import { Box, Card, CardActionArea, CardContent, Chip, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { jobTypeOptions, shiftTypeOptions } from "@src/appV2/redesign/PlacementCandidate/constants";

import { type JobType, type ShiftNameType } from "../../PlacementCandidate/types";
import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";
import { getPlacementDetailsPath } from "../paths";
import { PlacementPerk } from "../types/fetchPlacements.schema";
import { type PayRate } from "../types/payRate";
import { hasAppliedToPlacement } from "../utils";
import { PlacementCardChip } from "./PlacementCardChip";
import { PlacementCardPay } from "./PlacementCardPay";

interface PlacementCardProps {
  placement: PlacementWithDetails;
  placementCandidateId: string;
  pageIndex: number;
  placementIndex: number;
  payRate: PayRate | undefined;
  loadingPayData: boolean;
  shiftTypeFilter: ShiftNameType[] | undefined;
  jobTypeFilter: JobType[] | undefined;
  onPayRateClick: () => void;
}

const PAY_RATE_LABELS = {
  RANGE: "Pay range",
  FROM: "From",
  UP_TO: "Up to",
} as const;

export function PlacementCard(props: PlacementCardProps) {
  const {
    placement,
    placementCandidateId,
    pageIndex,
    placementIndex,
    payRate,
    loadingPayData,
    shiftTypeFilter,
    jobTypeFilter,
    onPayRateClick,
  } = props;

  const hasApplied = hasAppliedToPlacement(placement);
  const appliedDate = placement.applications?.[0]?.attributes.appliedDate;

  const payRateExperiment = usePayTransparencyFlag();

  const hasPayRateRange =
    isDefined(payRate) &&
    isDefined(payRate.min) &&
    isDefined(payRate.max) &&
    payRate.max !== payRate.min;

  function getPayRateLabel() {
    if (payRateExperiment.enabled && payRate?.externallyDerived) {
      return payRateExperiment.labelText;
    }

    if (hasPayRateRange) {
      return PAY_RATE_LABELS.RANGE;
    }

    if (!hasPayRateRange && placement.payRange?.minPay && !placement.payRange?.maxPay) {
      return PAY_RATE_LABELS.FROM;
    }

    if (!hasPayRateRange && placement.payRange?.maxPay) {
      return PAY_RATE_LABELS.UP_TO;
    }

    return null;
  }

  const payRateLabel = getPayRateLabel();

  const shiftTypeAndSpecialPerkElements = [
    ...placement.shiftTypes.map((shiftType) => {
      const shiftTypeOption = shiftTypeOptions.find((option) => option.value === shiftType);
      if (!isDefined(shiftTypeOption)) {
        return null;
      }

      return (
        <Box key={shiftType} component="span">
          <CbhIcon type={shiftTypeOption.icon} size="xSmall" />
        </Box>
      );
    }),
    placement.perks?.includes(PlacementPerk.TWELVE_SHIFTS_AVAILABLE) ? (
      <Text key="12h-shifts" variant="body2" color="text.secondary">
        12h shifts
      </Text>
    ) : null,
  ].filter(Boolean);

  return (
    <Card
      variant="elevation"
      sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
    >
      <CardActionArea
        component={BaseLink}
        to={getPlacementDetailsPath(placement.id, placementCandidateId)}
        onClick={() => {
          logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_VIEWED, {
            placementCandidateId,
            placementDetails: placement,
            page: pageIndex + 1,
            listIndex: placementIndex + 1,
            hasApplied,
            appliedDate,
            source: "placement-card",
          });
        }}
      >
        <CardContent>
          <Stack spacing={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Stack spacing={4} flex={1}>
                <PlacementCardChip placement={placement} appliedDate={appliedDate} />
                <Stack direction="column" spacing={1}>
                  <Text
                    semibold
                    variant="body1"
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    {placement.workplace.name}
                  </Text>
                  <Text variant="caption" color="text.secondary">
                    {formatDistanceInMiles(placement.distance)} from home
                  </Text>
                </Stack>
              </Stack>
              <Stack alignItems="center" spacing={1}>
                {isDefined(placement.workerTypes) && (
                  <Chip
                    label={
                      placement.workerTypes.length > 1
                        ? `${placement.workerTypes[0]} + ${placement.workerTypes.length - 1} more`
                        : placement.workerTypes[0]
                    }
                    variant="outlined"
                    size="small"
                    sx={{
                      border: "0.5px solid",
                      borderColor: (theme) => theme.palette.border?.primary,
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Stack spacing={1}>
                <Text variant="body2">
                  {placement.jobTypes
                    .map(
                      (jobType) => jobTypeOptions.find((option) => option.value === jobType)?.label
                    )
                    .join(", ")}
                </Text>

                <DotSeparatedList>{shiftTypeAndSpecialPerkElements}</DotSeparatedList>
              </Stack>

              <PlacementCardPay
                placement={placement}
                placementCandidateId={placementCandidateId}
                payRate={payRate}
                label={payRateLabel ?? undefined}
                loadingPayData={loadingPayData}
                shiftTypeFilter={shiftTypeFilter}
                jobTypeFilter={jobTypeFilter}
                onClick={onPayRateClick}
              />
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
