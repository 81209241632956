import { Pill } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { formatDistance, parseISO } from "date-fns";

import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { PlacementApplicationStatus } from "../types/fetchPlacements.schema";
import {
  hasAppliedToPlacement,
  hasBookedInterview,
  hasCancelledInterview,
  interviewRequired,
} from "../utils";
import { PlacementApplicationStatusChip } from "./PlacementApplicationStatusChip";

const HIDDEN_APPLICATION_STATUSES = new Set([
  PlacementApplicationStatus.APPLIED,
  PlacementApplicationStatus.ACCEPTED,
  PlacementApplicationStatus.REJECTED,
]);

interface PlacementCardChipProps {
  placement: PlacementWithDetails;
  appliedDate: string | undefined;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getPlacementChipDetails(placement: PlacementWithDetails, appliedDate: string | undefined) {
  const hasApplied = hasAppliedToPlacement(placement);
  const requiredInterview = interviewRequired(placement);
  const bookedInterview = hasBookedInterview(placement);
  const cancelledInterview = hasCancelledInterview(placement);

  if (hasApplied && (!requiredInterview || bookedInterview) && isDefined(appliedDate)) {
    return {
      iconType: "checkmark-filled" as const,
      label:
        requiredInterview && bookedInterview
          ? `Booked ${
              placement.workplace.placementSettings?.isProspect ? "a walk-in" : "an interview"
            } ${formatDistance(new Date(), parseISO(appliedDate))} ago`
          : `Applied ${formatDistance(new Date(), parseISO(appliedDate))} ago`,
    };
  }

  if (!hasApplied && !placement.isLicenseEligible) {
    return {
      iconType: "triangle-exclamation" as const,
      label: `${placement.workplace.state} license needed`,
      color: (theme: Theme) => theme.palette.intent?.warning.text,
      backgroundColor: (theme: Theme) => theme.palette.intent?.warning.background,
      iconColor: (theme: Theme) => theme.palette.intent?.warning.icon,
    };
  }

  if (requiredInterview && !hasApplied) {
    return {
      iconType: "bolt-filled" as const,
      label: `Same-day ${
        placement.workplace.placementSettings?.isProspect ? "" : "interview and"
      } offer`,
      color: (theme: Theme) => theme.palette.intent?.success.text,
      backgroundColor: (theme: Theme) => theme.palette.intent?.success.background,
    };
  }

  if (requiredInterview && hasApplied && cancelledInterview) {
    return {
      iconType: "triangle-exclamation" as const,
      label: `${
        placement.workplace.placementSettings?.isProspect ? "Walk-in" : "Interview"
      } canceled`,
      color: (theme: Theme) => theme.palette.intent?.disabled.text,
      backgroundColor: (theme: Theme) => theme.palette.intent?.disabled.background,
    };
  }

  return null;
}

export function PlacementCardChip({ placement, appliedDate }: PlacementCardChipProps) {
  const chipDetails = getPlacementChipDetails(placement, appliedDate);

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        {chipDetails && <Pill size="small" {...chipDetails} />}
        {hasAppliedToPlacement(placement) &&
          isDefined(placement.applications) &&
          !HIDDEN_APPLICATION_STATUSES.has(placement.applications[0]?.attributes?.status) && (
            <PlacementApplicationStatusChip status={placement.applications[0].attributes.status} />
          )}
      </Stack>
    </Box>
  );
}
