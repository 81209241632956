import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";

import { type PlacementDetail } from "../api/useGetPlacement";
import { getFormattedInterviewDate } from "../utils";

interface PlacementApplicationMessageProps {
  interviews: PlacementDetail["interviews"];
  workplaceName: string;
  workerTimezone: string;
}

export function PlacementApplicationMessage(props: PlacementApplicationMessageProps) {
  const { interviews, workplaceName, workerTimezone } = props;
  const interview = interviews.find(
    (interview) => interview.attributes.status === InterviewStatus.BOOKED
  );
  return isDefined(interview) ? (
    <Text variant="caption">
      You&apos;ve successfully applied for this job at {workplaceName}. Your interview is scheduled
      for {getFormattedInterviewDate(interview, workerTimezone)}.
    </Text>
  ) : (
    <Text variant="caption">
      You&apos;ve successfully applied for this job at {workplaceName}. If your application is
      approved, you&apos;ll receive an email with an interview invite from the facility!{" "}
    </Text>
  );
}
